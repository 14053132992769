import { createAction, props } from "@ngrx/store";
import { Constants } from "app/core/data/constants";
import { CandidateModel } from "app/core/models/candidate.model";


export const registerCredentials = createAction(Constants.REGISTER_CREDENTIALS_ACTIONS, props<{
  userCredentials: {
    login?: string | null,
    password?: string | null,
    confirm?: string | null,
  }
}>())

export const setMethodChosen = createAction(Constants.METHOD_CHOSEN_ACTIONS, props<{
  methodChosen : {
    method : string | null,
    candidate : CandidateModel | null
  },
}>())

export const userInformationsAction = createAction(Constants.USER_INFOS_ACTIONS, props<{ userInformations: any }>())
export const registerLoading = createAction(Constants.REGISTER_LOADING_ACTION, props<{ isLoading: boolean }>());
export const registerFailure = createAction(Constants.REGISTER_FAILURE_ACTION, props<{ registerError: string }>());
