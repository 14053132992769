import { createReducer, on } from "@ngrx/store";
import { initialSearchState } from "../states/search.state";
import { contractsFilterChanged,companiesFilterChanged ,postedDateFilterChanged, titleFilterChanged, locationFilterChanged, setFilters, notifyChildren, setPage, setSearchValues, posterDateStartEndDateFilterChanged, activityAreaFilterChanged, geographicalMobilityFilterChanged, minActivityRateFilterChanged, maxActivityRateFilterChanged, pulishedDateFilterChanged,} from "../actions/search.actions";


export const searchReducer = createReducer(
  initialSearchState,

  on(setSearchValues,(state, {filters,page,notify})=>({...state,filters,page,notify})),

  on(setFilters,(state, {filters,notify})=>({...state,filters,notify})),

  on(setPage,(state, {page})=>({...state,page})),

  on(titleFilterChanged,(state,{title})=>({...state,filters : {
    ...state.filters,
    title
  }})),

  on(locationFilterChanged,(state,{location})=>({...state,filters : {
    ...state.filters,
    location
  }})),

  on(contractsFilterChanged,(state,{contracts})=>({...state,filters : {
    ...state.filters,
    contracts
  }})),
  on(companiesFilterChanged,(state,{companies})=>({...state,filters : {
    ...state.filters,
    companies
  }})),
  on(postedDateFilterChanged,(state,{postedDate})=>({...state,filters : {
    ...state.filters,
    postedDate
  }})),

  on(notifyChildren,(state,{notify})=>({
    ...state,
    notify
  })),
  
 on(posterDateStartEndDateFilterChanged,(state,{startDate,endDate})=>({...state,filters : {
    ...state.filters,
    startDate,
    endDate
  }})),
  
  on(activityAreaFilterChanged,(state,{ activityArea })=>({...state,filters : {
    ...state.filters,
    activityArea
  }})),

  on(geographicalMobilityFilterChanged,(state,{ geographicalMobility })=>({...state,filters : {
    ...state.filters,
    geographicalMobility
  }})),

  on(maxActivityRateFilterChanged,(state,{ maxActivityRate })=>({...state,filters : {
    ...state.filters,
    maxActivityRate
  }})),
  
  on(minActivityRateFilterChanged,(state,{ minActivityRate })=>({...state,filters : {
    ...state.filters,
    minActivityRate
  }})),

  on(pulishedDateFilterChanged,(state,{ publishedDate })=>({...state,filters : {
    ...state.filters,
    publishedDate
  }})),

);
