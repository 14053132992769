import { LabelValueModel } from "app/core/models/filters.model";


export interface SearchState {
  filters: FiltersStateModel | null,
  notify?: boolean | null,
  page: any
}

export const initialSearchState : SearchState = {
  filters:null,
  notify: true,
  page:0,
}


export interface FiltersStateModel {
  title : string | null,
  location : string | null,
  contracts: LabelValueModel[] | null,
  companies :string[] | null,
  postedDate : string | null,
  startDate : string | null,
  endDate : string | null,
  activityArea : string[] | null,
  geographicalMobility : string[] | null,
  maxActivityRate : string | null,
  minActivityRate : string | null,
  publishedDate: string | null,
}
