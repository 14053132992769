import { createReducer, on } from "@ngrx/store";
import { initialRegisterState } from "../states/register.state";
import { setMethodChosen, registerCredentials, registerFailure, registerLoading, userInformationsAction } from "../actions/register.actions";



export const registerReducer = createReducer(
  initialRegisterState,
  on(registerCredentials,(state,{userCredentials})=>({...state, userCredentials})),
  on(setMethodChosen,(state,{methodChosen,})=>({...state, methodChosen })),
  on(userInformationsAction,(state,{userInformations})=>({...state,userInformations})),
  on(registerLoading,(state,{isLoading})=>({...state,isLoading})),
  on(registerFailure,(state,{registerError})=>({...state,registerError}))
)
