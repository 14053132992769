import { CandidateModel } from "app/core/models/candidate.model"

export interface RegisterState {
    userCredentials : {
      login?:string | null,
      password?:string | null,
      confirm?:string | null,
    } | null,
    methodChosen : {
      method : string | null,
      candidate : CandidateModel | null
    },
    userInformations : {},
    isLoading:boolean,
    registerError:string | null
}

export const initialRegisterState : RegisterState = {
  userCredentials : null,
  methodChosen : null,
  userInformations: null,
  isLoading:false,
  registerError: null,
}
