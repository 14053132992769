import { createReducer, on } from "@ngrx/store";
import { initialAuthenticationState } from "../states/authentication.state";
import { login, loginFailure, loginLoading, loginSuccess, logout, setIsFirstLogin } from "../actions/authentication.action";


export const authenticationReducer = createReducer(
    initialAuthenticationState,
    on(login, state => ({ ...state, isLoggedIn: false, accessToken: null, loginError: null, })),
    on(loginLoading, (state, { isLoading }) => ({ ...state, isLoading })),
    on(loginSuccess, (state, { accessToken, }) => ({ ...state,  isLoggedIn:true ,accessToken, })),
    on(setIsFirstLogin, (state, { isFirstLogin }) => ({...state,isFirstLogin,})),
    on(loginFailure, (state, { loginError }) => ({ ...state, loginError, isLoggedIn:false })),
    on(logout, state => ({ ...state, accessToken: null, isLoggedIn: false }))
);
