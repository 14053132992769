import { isDevMode } from '@angular/core';
import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import { authenticationReducer } from './authentication.reducer';
import { registerReducer } from './register.reducer';
import { AuthenticationState } from '../states/authentication.state';
import { RegisterState } from '../states/register.state';
import { SearchState } from '../states/search.state';
import { searchReducer } from './search.reducer';

export interface AppState {
  authentication : AuthenticationState,
  register : RegisterState,
  search : SearchState
}

export const reducers: ActionReducerMap<AppState> = {
    authentication : authenticationReducer,
    register : registerReducer,
    search: searchReducer
};

export const selectAuthentication = createFeatureSelector<AuthenticationState>('authentication')
export const selectRegister = createFeatureSelector<RegisterState>('register');
export const selectSearch = createFeatureSelector<SearchState>('search');


export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [] : [];
