import { createAction, props } from "@ngrx/store";
import { Constants } from "app/core/data/constants";
import { LabelValueModel } from "app/core/models/filters.model";


export const setPage = createAction(Constants.SET_PAGE_VALUES,props<{page:any}>());

export const setFilters = createAction(Constants.SET_FILTERS_VALUES, props<{
  filters: {
    title: string | null,
    location: string | null,
    contracts: LabelValueModel[] | null,
    companies: string[] | null,
    postedDate: string | null,
    startDate: string | null,
    endDate: string | null,
    activityArea : string[] | null,
    geographicalMobility : string[] | null,
    maxActivityRate : string | null,
    minActivityRate : string | null,
    publishedDate : string | null

  },
  notify?:boolean | null,
}>())

export const setSearchValues = createAction(Constants.SET_SEARCH_VALUES, props<{
  filters: {
    title: string | null,
    location: string | null,
    contracts: LabelValueModel[] | null,
    companies: string[] | null,
    postedDate: string | null,
    startDate: string | null,
    endDate: string | null,
    activityArea : string[] | null,
    geographicalMobility : string[] | null,
    maxActivityRate : string | null,
    minActivityRate : string | null,
    publishedDate: string | null,
  },
  notify?:boolean | null,
  page?:any
}>())


export const titleFilterChanged = createAction(Constants.TITLE_FILTER_CHANGED, props<{
  title: string,
}>())

export const locationFilterChanged = createAction(Constants.LOCATION_FILTER_CHANGED, props<{
  location: string,
}>())

export const contractsFilterChanged = createAction(Constants.CONTRACTS_FILTER_CHANGED_ACTION, props<{
  contracts: LabelValueModel[],
}>())

export const companiesFilterChanged = createAction(Constants.COMPANIES_FILTER_CHANGED_ACTION, props<{
  companies: string[],
}>())


export const postedDateFilterChanged = createAction(Constants.POSTED_DATE_FILTER_CHANGED_ACTION, props<{
  postedDate: string,
}>())

export const notifyChildren = createAction(Constants.NOTIFY_CHILDREN_ACTION,props<{
  notify: boolean,
}>())

export const posterDateStartEndDateFilterChanged = createAction(Constants.POSTED_DATE_FILTER_CHANGED_ACTION, props<{
   startDate: string,
    endDate: string,
}>())
export const activityAreaFilterChanged = createAction(Constants.ACTIVITY_AREA_FILTER_CHANGED, props<{
  activityArea: string[],
}>())

export const geographicalMobilityFilterChanged = createAction(Constants.GEOGRAPHICAL_MOBILITY_FILTER_CHANGED, props<{
  geographicalMobility: string[],
}>())

export const maxActivityRateFilterChanged = createAction(Constants.MAX_ACTIVITY_RATE_FILTER_ACTION, props<{
  maxActivityRate: string,
}>())

export const minActivityRateFilterChanged = createAction(Constants.MIN_ACTIVITY_RATE_FILTER_ACTION, props<{
  minActivityRate: string,
}>())
export const pulishedDateFilterChanged = createAction(Constants.PUBLISHED_DATE_FILTER_ACTION, props<{
  publishedDate: string,
}>())